<template>
  <v-container class="pt-0">
    <back-toolbar :title="$t('notifications.title')"></back-toolbar>
    <error-image
      :text="$t('notifications.noNotifications')"
      imageName="noNotifications.png"
    ></error-image>
  </v-container>
</template>

<script>
import BackToolbar from "@/components/app/common/BackToolbar";
import ErrorImage from "@/components/app/common/ErrorImage";
export default {
  components: {
    BackToolbar,
    ErrorImage,
  },
};
</script>
